import type { CourseLightModel, CourseModel, SessionLightModel, SessionModel } from '@24hbs/utils';
import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';

export interface CourseTag {
  slug: string;
  value: number;
  ranking: number;
  isNew: boolean;
}

const MAX_COLORFUL_TAGS_PER_CORUSE = 2;
const MAX_TEXT_TAGS_PER_CORUSE = 2;

export interface TagsColorfulList {
  best_seller: CourseTag | null;
  il_piu_cercato_dalle_aziende: CourseTag | null;
  consigliato_da_24_ore_bs: CourseTag | null;
  il_piu_apprezzato_dagli_studenti: CourseTag | null;
  top_networking: CourseTag | null;
  trend_topic: CourseTag | null;
}


export interface TagsTextList {
  fruizione_flessibile: CourseTag | null;
  nuovi_contenuti: CourseTag | null;
  workshop_esclusivo: CourseTag | null;
  intensivo: CourseTag | null;
  livello_avanzato: CourseTag | null;
}

export interface TagsList{
  colorfulTags: TagsColorfulList;
  textTags: TagsTextList;
}



export class TagStore {

  private _tagStore: Writable<TagsList> = writable(this.initTagsList());
  private _tagReadyStore: Writable<boolean> = writable(false);
  private static _instance: TagStore | null = null;

  private constructor() {
  }

  public static getInstance() {
    if (TagStore._instance === null) {
      TagStore._instance = new TagStore();
    }
    return TagStore._instance;
  }

  public static getEmptyTagList(): TagsList {
    return {
      colorfulTags: {
        best_seller: null,
        il_piu_cercato_dalle_aziende: null,
        consigliato_da_24_ore_bs: null,
        il_piu_apprezzato_dagli_studenti: null,
        top_networking: null,
        trend_topic: null
      },
      textTags: {
        fruizione_flessibile: null,
        nuovi_contenuti: null,
        workshop_esclusivo: null,
        intensivo: null,
        livello_avanzato: null
      }
    }
  }

  public get tagStore(): Writable<TagsList> {
    return this._tagStore;
  }

  public get tagReadyStore(): Writable<boolean> {
    return this._tagReadyStore;
  }

  // Re-Init tag store and flag
  public initTagStore() {
    this._tagStore.update( () => {
      return this.initTagsList();
    });
    this._tagReadyStore.update( () => {
      return false;
    });
  }

  private freezeShowedTags() {
    this._tagStore.update( current => {
      for (const key of Object.keys(current.colorfulTags)) {

        if(current.colorfulTags[key]!==null) {
          current.colorfulTags[key].isNew = false;
        }
        else{
        }
      }
      for (const key of Object.keys(current.textTags)) {
        if(current.textTags[key]!==null) {
          current.textTags[key].isNew = false;
        }
        else{
          //console.log("Is null: ",key," : ",current.textTags[key]);
        }
      }

      return current;

    });
  }

  // Set ready flag
  private tagStoreReady() {
    this.freezeShowedTags();
    this._tagReadyStore.update( () => {
      return true;
    });
  }

  private static getCountColorfulForSlug(colorfulTags: TagsColorfulList, session: CourseModel | CourseLightModel | SessionModel | SessionLightModel): number{
    let counter = 0;
    for (const tag of Object.values(colorfulTags)) {
      if(tag !== null && tag.slug === session.slug){
        counter++;
      }
    }
    return counter;
  }

  private static getCountTextForSlug(textTags: TagsTextList, session: CourseModel | CourseLightModel | SessionModel | SessionLightModel): number {
    let counter = 0;
    for (const tag of Object.values(textTags)) {
      if(tag !== null && tag.slug === session.slug){
        counter++;
      }
    }
    return counter;
  }

  public async updateTagsForSession(session: CourseModel | CourseLightModel | SessionModel | SessionLightModel, isLast: boolean) {

      //console.log("------------",session.slug);
      //console.log("Requesting update tags for ", session.slug, " containing tags:", session.tags);
      if(isLast || (session.tags && Object.keys(session.tags).length>0)) {
        this._tagStore.update( current => {
          if(session.tags && Object.keys(session.tags).length>0) {
            let currentColorfulTags = TagStore.getCountColorfulForSlug(current.colorfulTags, session);
            let currentTextTags = TagStore.getCountTextForSlug(current.textTags, session)
            if (currentColorfulTags < MAX_COLORFUL_TAGS_PER_CORUSE) {
              for (const key of Object.keys(current.colorfulTags)) {
                if (!session.tags[key]) {
                  continue;
                }
                if (current.colorfulTags[key] === null || (
                    (current.colorfulTags[key] !== null && current.colorfulTags[key].isNew &&
                        (session.tags[key] > current.colorfulTags[key].value ||
                            (session.tags[key] == current.colorfulTags[key].value && session.ranking > current.colorfulTags[key].ranking))))) {

                  current.colorfulTags[key] = {
                    slug: session.slug,
                    value: session.tags[key],
                    ranking: session.ranking,
                    isNew: true
                  }

                  currentColorfulTags++;
                  if (currentColorfulTags >= MAX_COLORFUL_TAGS_PER_CORUSE) {

                    break;
                  }
                }
              }
            }
            if (currentTextTags < MAX_TEXT_TAGS_PER_CORUSE) {
              for (const key of Object.keys(current.textTags)) {
                if (!session.tags[key]) {
                  continue;
                }
                if (current.textTags[key] === null || (
                    (current.textTags[key] !== null && current.textTags[key].isNew &&
                        (session.tags[key] > current.textTags[key].value ||
                            (session.tags[key] == current.textTags[key].value && session.ranking > current.textTags[key].ranking))))) {

                  current.textTags[key] = {
                    slug: session.slug,
                    value: session.tags[key],
                    ranking: session.ranking,
                    isNew: true
                  }

                  currentTextTags++;
                  if (currentTextTags >= MAX_TEXT_TAGS_PER_CORUSE) {

                    break;
                  }
                }
              }
            }
          }
          if(isLast){
            //Just to make this async
            setTimeout(() => {
              this.tagStoreReady();
            },1)
          }

          return current;
        });
      }
  }

  public static getColorfulTagForCourse(tags: TagsList, session: CourseModel | CourseLightModel | SessionModel | SessionLightModel): string[]{
    if(!tags.colorfulTags || tags.colorfulTags===null || Object.keys(tags.colorfulTags).length<=0){
      return [];
    }
    const myTags: string[] = [];
    for (const key of Object.keys(tags.colorfulTags)) {
      if(tags.colorfulTags[key]!==null && tags.colorfulTags[key].slug === session.slug){
        myTags.push(key);
      }
    }
    return myTags;
  }

  public static getTextualTagForCourse(tags: TagsList, session: CourseModel | CourseLightModel | SessionModel | SessionLightModel): string[]{
    if(!tags.textTags || tags.textTags===null || Object.keys(tags.textTags).length<=0){
      return [];
    }
    const myTags: string[] = [];
    for (const key of Object.keys(tags.textTags)) {
      if(tags.textTags[key]!==null && tags.textTags[key].slug === session.slug){
        myTags.push(key);
      }
    }
    return myTags;
  }

  private initTagsList(): TagsList {
    return TagStore.getEmptyTagList();
  }


}
