<script lang="ts">
  import classnames from 'classnames';
  import type { SessionLightModel, SessionModel } from '@24hbs/utils';

  export let fullwidth: boolean = false;
  export let onSchedule: boolean = false;
  export let onBanner: boolean = false;
  export let session: SessionModel | SessionLightModel;
</script>

<span
  class={classnames('badge-latest-seats', $$props.class, {
    fullwidth,
    onSchedule,
    onBanner,
    ...(session.promoLabelStyle && { [session.promoLabelStyle]: true }),
  })}
>
  <span class="badge-text">{session.promoDetailLabel}</span>
</span>

<style type="text/scss" lang="scss" scoped>
  .onSchedule {
    position: relative;
    width: calc(100% + 32px);
    margin-left: -16px;
    top: 16px;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }

  @media only screen and (min-width: 768px) {
    .onSchedule {
      border-bottom-left-radius: 0;
    }
  }

  @media only screen and (min-width: 1024px) {
    .onSchedule {
      left: 24px;
    }
  }

  @media only screen and (min-width: 753px) and (max-width: 1023px) {
    .onSchedule {
      margin-left: -8px;
    }
  }
  /*

   @screen md {
      left: 24px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0.3rem;
    }

 
  */

  .fullwidth {
    width: calc(100% + 40px);
    margin-left: -20px;

    &.onBanner {
      width: 100%;
      margin-left: 0;
    }
  }

  .badge-latest-seats {
    @apply flex items-center justify-center;
    @apply px-3;
    @apply h-8;
    @apply bg-petrol;
    @apply text-white;

    &.primi_posti_advance_booking {
      @apply bg-label-primi_posti_advance_booking;
    }
    &.sconto_i {
      @apply bg-label-sconto_i;
    }
    &.super_sconto {
      @apply bg-label-super_sconto;
    }
    &.sconto_ii {
      @apply bg-label-sconto_ii;
    }
    &.nuovo_titolo {
      @apply bg-label-nuovo_titolo text-black;
    }
    &.libero_non_assegnato {
      @apply bg-label-libero_non_assegnato;
    }
  }

  .badge-text {
    @apply font-sans font-semibold text-xs uppercase tracking-wide;
  }
</style>
