<script lang="ts">
  import classNames from "classnames";

  export let tags: string[] = [];
  export let textOnly: boolean = false;
  export let allTags: boolean = false;

  const textOnlyTags = [
    'fruizione_flessibile',
    'nuovi_contenuti',
    'workshop_esclusivo',
    'intensivo',
    'livello_avanzato',
    'in_inglese',
    'borsa_di_studio_fuorisede'
  ];

  const tagLabels = {
    best_seller: 'Best seller',
    il_piu_cercato_dalle_aziende: 'Il più cercato dalle aziende',
    consigliato_da_24_ore_bs: 'Consigliato da 24 ore bs',
    fruizione_flessibile: 'Fruizione flessibile',
    il_piu_apprezzato_dagli_studenti: 'Il più ricercato dagli studenti',
    nuovi_contenuti: 'Nuovi contenuti',
    workshop_esclusivo: 'Workshop esclusivo',
    top_networking: 'Top networking',
    intensivo: 'Intensivo',
    livello_avanzato: 'Livello avanzato',
    trend_topic: 'Trend topic',
    in_inglese: 'In lingua inglese',
    borsa_di_studio_fuorisede: 'Borsa di studio fuori sede'
  }

</script>

<ul
  class={classNames("tags", {
    ...(!textOnly && { ['flex']: true }),
  })}
>
  {#each tags as tag}
    {#if allTags}
      <li class={classNames("tag textual", tag)}>
        {tagLabels[tag]}
      </li>
    {:else if textOnly && textOnlyTags.includes(tag)}
      <li class={classNames("tag textual", tag)}>
        {tagLabels[tag]}
      </li>
    {:else if !textOnly && !textOnlyTags.includes(tag)}
      <li class={classNames("tag", tag)}>
        {tagLabels[tag]}
      </li>
    {/if}
  {/each}
</ul>

<style type="text/scss" lang="scss" scoped>
.tags {
  /* Space between list items */
  --space-x: theme('spacing.2');
  --space-y: theme('spacing.4');

  @screen md {
    --space-y: theme('spacing.5');
  }

  @apply flex-wrap;
  /* Trick to re-create flex-gap for browsers that don't support it */
  margin-left: calc(var(--space-x) * -1);
}
.tag {
  @apply text-xxs;
  @apply md:text-xs;
  @apply lg:text-xxs;
  @apply xl:text-xs;
  @apply flex items-center;
  @apply py-1 px-3;
  @apply text-black font-semibold uppercase tracking-wide;
  @apply border-t border-r border-b border-l border-gray-600 rounded;
  margin-left: var(--space-x);
  margin-top: var(--space-y);
  width: max-content;

  &.textual {
    margin-top: .5rem;
  }

  &.best_seller {
    @apply text-white bg-tag-best_seller;
    @apply border-0;
  }
  &.il_piu_cercato_dalle_aziende {
    @apply text-white bg-tag-il_piu_cercato_dalle_aziende;
    @apply border-0;
  }
  &.consigliato_da_24_ore_bs {
    @apply text-white bg-tag-consigliato_da_24_ore_bs;
    @apply border-0;
  }
  &.il_piu_apprezzato_dagli_studenti {
    @apply text-white bg-tag-il_piu_apprezzato_dagli_studenti;
    @apply border-0;
  }
  &.top_networking {
    @apply text-white bg-tag-top_networking;
    @apply border-0;
  }
  &.trend_topic {
    @apply text-white bg-tag-trend_topic;
    @apply border-0;
  }
}
</style>